<template>
  <b-row class="autoFillBtn">
    <Avatar
      size="40"
      :src="patient?.picture"
      :status="status || patient?.status"
    />
    <b-col class="patient-informations name-container">
      <div class="vertical-align-middle inline-overflow name-width">
          {{ patient?.name }}
      </div>
      <div class="row">
        <div v-if="showCpf">{{ patient?.cpf }}</div>
        <div class="divisor" v-if="showCpf">|</div>
        <div>
          {{ getBirthdayDate(patient.birthday) }}
        </div>
        <div class="divisor">|</div>
        <div>
          {{ getAge(patient?.birthday) }}
        </div>
        <div class="divisor">|</div>
        <b-link
          v-b-tooltip.hover.bottom="'Acessar Prontuário'"
          @click="goToPatientDashboard(patient.id)"
        >
          <Export />
        </b-link>
        <div class="divisor">|</div>
        <b-link
          v-if="patient.cellphone || patient.cellphone2"
          v-b-tooltip.hover.bottom="'Acessar whatsapp'"
          @click="openWhatsApp(patient.cellphone ?? patient.cellphone2)"
        >
          <WhatsappIcon />
        </b-link>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import moment from 'moment'

export default {
  name: 'PatientBasicInfo',
  components: {
    Avatar: () => import('@/components/General/Avatar'),
    Export: () => import('@/assets/icons/export.svg'),
    WhatsappIcon: () => import('@/assets/icons/whatsapp-icon.svg')
  },
  props: {
    patient: {
      type: Object,
      required: true
    },
    showCpf: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      default: ''
    }
  },

  methods: {
    getBirthdayDate(birthday) {
      if (!birthday) return '-'
      return moment(birthday).format('DD/MM/YYYY')
    },

    getAge(birthday) {
      const days = moment().diff(moment(birthday), 'days')
      const months = moment().diff(moment(birthday), 'month')
      const years = moment().diff(moment(birthday), 'years')

      if (!birthday) {
        return '-'
      } else if (months > 1 && months < 12) {
        return `(${months} meses)`
      } else if (days <= 30) {
        return `(${days} dias)`
      } else {
        return `${years} anos`
      }
    },
    openWhatsApp(patient) {
      const whatsappNumber = patient.replace(/\D/g, '')
      if(whatsappNumber.length < 10){
        this.$toast.warning('Número de telefone sem DDD')
        return
      }
        window.open(`https://api.whatsapp.com/send?phone=55${whatsappNumber}`, '_blank')
    },
    goToPatientDashboard(id) {
      const route = id ? this.$router.resolve('/pacientes/' + id ) : this.$router.resolve('/pacientes/' + this.lastPatient.id );
      window.open(route.href, '_blank');
    },
  }
}
</script>
<style lang="scss" scoped>
.name-container {
  width: 60%;
}

.autoFillBtn {
  padding-left: 8px !important;
  margin-bottom: 2px !important;
}

.patient-informations {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;

  .row {
    display: flex;
    flex-direction: row !important;
    margin-left: 0px;
    margin-right: 0px;

    @media (max-width: 820px) {
      justify-content: center !important;
    }
  }
  .divisor {
    margin: 0 5px;
    color: #d9dff2;
    @media screen and (max-width: 1280px) {
      color: white;
    }
  }

  .icon {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;

    &.normal {
      color: #4a4a4a;
    }
    &.low {
      color: #305bf2;
    }
    &.medium {
      color: #00c773;
    }
    &.high {
      color: #ffcc18;
    }
    &.very-high {
      color: #f63220;
    }
  }
}
</style>
